import { Typography, Box } from "@material-ui/core";

// Translation
import { useTranslation } from "../../i18n";

// Components
import { ImageModal, MasonryGrid, Link, Section } from "../common";
import { MoreButton } from "./styles";

const Gallery = ({ galleryImages }) => {
  const { t } = useTranslation("home");

  const images = galleryImages.map(({ image }) => {
    return <ImageModal image={image} key={image} landing={"true"} />;
  });

  return (
    <Section  bgimage="/images/moments.png">
      <Box color="black" py={5}>
        <Typography style={{color:"white"}} variant="h3" align="center" gutterBottom>
          {t("gallery.title")}
        </Typography>
      </Box>
      <MasonryGrid md={3}>{images}</MasonryGrid>
      <Box py={4} justifyContent="center" display="flex">
        <Box bgcolor="common.black" borderRadius={4}>
          <MoreButton size="large">
            <Link href="/gallery" color="inherit" underline="none">
              {t("seeMore")}
            </Link>
          </MoreButton>
        </Box>
      </Box>
    </Section>
  );
};

export default Gallery;
