import { Box, Typography, Button, Hidden, Grid } from "@material-ui/core";
//Translation
import { useTranslation } from "../../i18n";

// Components
import { Section, FullWidthImage, Link } from "../common";
import { HeaderButton } from "./styles";

const About = () => {
  const { t } = useTranslation("home");

  return (


    <Section style={{background:"linear-gradient(0.25turn, #eff3c6 ,60%, #9CD99E)"}}>
      <Grid container alignItems="center" justify="space-between">
        <Grid item md={4}>
          <Box mb={2}>
            <Typography variant="h1">{t("header.title")}</Typography>
          </Box>

          <Box mb={3}>
            <Typography variant="h5">{t("header.paragraph")}</Typography>
          </Box>

          <HeaderButton variant="contained" color="primary" size="large">
            <Link color="inherit" href="#about" underline="none">
              {t("header.button")}
            </Link>
          </HeaderButton>
        </Grid>
        <Hidden mdUp>
          {/* this hack adds margin between the items on mobile views*/}
          <Box my={2} />
        </Hidden>
        <Box clone display="flex" flexDirection="row" justifyContent="flex-start">

        <Grid item md={8}>
            <img width="100%" src="/images/sec1.png" role="presentation" />
        </Grid>
        </Box>
      </Grid>
    </Section>
 );
};

export default About;
