import { Icon } from "@material-ui/core";

export default {
  en: [
    "6th to 12th grade student",
    "Interested in STEM, innovation, and leadership",
    "Has a passion and desire to impact their school and community",
    "Actively participates in a NASA Globe training institute and year-round activities at school and in the community",
  ],
  ar: [
    "طلبة الصف السادس إلى الثاني عشر",
    "مهتمون بمجالات العلوم و التكنولوجيا و الهندسة و الرياضيات، والابتكار والقيادة",
    "لديهم شغف ورغبة بالتأثير في مدرستهم ومجتمعهم",
    "يشاركون بفاعلية في الملتقي التدريبي لمديري العلوم التنفيذيين والأنشطة الأخرى طوال العام في مدراسهم ومجتمعهم",
  ],
  icons: [
    <Icon className="fas fa-user-graduate" />,
    <Icon className="fas fa-flask" />,
    <Icon className="fas fa-fist-raised" />,
    <Icon className="fas fa-chalkboard-teacher" />,
  ],
};
