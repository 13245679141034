import { Box } from "@material-ui/core";

// Components
import EventList from "../Events/EventList";
import { MoreButton } from "./styles";
import { Section, Link } from "../common";

// Translation
import { useTranslation } from "../../i18n";

const Events = ({ events }) => {
  const { t } = useTranslation("home");
  //bgimage={"/images/2.png"}
  return (
    <Section style={{background:"linear-gradient(0.25turn, #eff3c6 ,60%, #9CD99E)"}} pt={15} pb={10}>
      <EventList events={events} color="#1C668E" landing={"true"} />
      <Box py={4} justifyContent="center" display="flex">
        <MoreButton size="large">
          <Link href="/events" color="#1C668E" underline="none">
            {t("seeMore")}
          </Link>
        </MoreButton>
      </Box>
    </Section>
  );
};

export default Events;
