import { Icon } from "@material-ui/core";

const goals = [
  {
    title: "Leadership",
    titleAr: "قيادة",
    description: "Cultivate a pathway of diverse STEM leaders",
    descriptionAr: "إفساح الطريق أمام قادة علوم متنوعي المشارب",
    icon: <Icon className="fas fa-crown" />,
  },
  {
    title: "Communication",
    titleAr: "تواصل",
    description: "Foster communication and collaboration among CSOs",
    descriptionAr: "تعزيز الاتصال والتعاون بين مديري العلوم التنفيذيين",
    icon: <Icon className="fas fa-project-diagram" />,
  },
  {
    title: "Culture",
    titleAr: "ثقافة",
    description: "Enrich school STEM culture and career awareness",
    descriptionAr:
      "إثراء ثقافة مجالات العلوم و التكنولوجيا و الهندسة و الرياضيات في المدارس وتعزيز الوعي بالمهن العلمية المتاحة",
    icon: <Icon className="fas fa-graduation-cap" />,
  },
  {
    title: "Voice",
    titleAr: "صوت",
    description: "Increase youth voice in STEM conversations in the community",
    descriptionAr:
      "إتاحة الفرص لإسماع صوت الشباب في الحوارات الدائرة حول مجالات العلوم و التكنولوجيا و الهندسة و الرياضيات في المجتمع",
    icon: <Icon className="fas fa-bullhorn" />,
  },
];

export default goals;
