import React from "react";
import { Box, Link, Typography } from "@material-ui/core";

// Components
import { Section } from "../common";
import TeamMembers from "../Teams/TeamMembers";

// Translation
import { useTranslation } from "../../i18n";
import { MoreButton } from "./styles";

const Teams = ({ teams }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation("home");
  const isEnglish = language === "en";

  const teamRows = teams.map((team) => (
    <TeamMembers color="white" key={team.id} {...team} landing={"true"} />
  ));
  return (
    <Section
      bgcolor="common.white"
      pt={10}
      pb={10}

    >
        <Typography variant="h3">{t("teams.title")}</Typography>
      {teamRows}
      <Box py={4} justifyContent="center" display="flex">
        <MoreButton size="large">
          <Link href="/teams" color="inherit" underline="none">
            {t("seeMore")}
          </Link>
        </MoreButton>
      </Box>
    </Section>
  );
};

export default Teams;
