import { Button } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

export const HeaderButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  color: theme.palette.background.paper,
  paddingLeft: theme.spacing(6),
  paddingRight: theme.spacing(6),
}));

export const MoreButton = styled(Button)(({ theme }) => ({
  background: "rgba(256,256,256,0.1)",
  color: "#fff",
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
}));
