import React from "react";
import { Card, CardContent, Grid, Typography, Box } from "@material-ui/core";

// Translation
import { useTranslation } from "../../../i18n";

// Components
import { Link, CardImage, ShadowBox } from "../../common";

const EventCard = ({
  name,
  nameAr,
  dateLabel,
  dateLabelAr,
  images,
  slug,
  grey,
}) => {
  const {
    i18n: { language },
  } = useTranslation();

  const isEnglish = language === "en";
  const image = images.length ? images[0].image : "";

  return (
    <Grid item md={4} xs={12}>
      <Link
        href="/events/[eventSlug]"
        as={`/events/${slug}`}
        color="inherit"
        underline="none"
      >
        <ShadowBox clone>
          <Box height="100%" clone>
            <Card>
              <CardImage
                image={image}
                title={isEnglish ? image.caption : image.captionAr}
                grey={grey}
              />
              <CardContent>
                <Box mb={2}>
                  <Typography variant="caption">
                    {isEnglish ? dateLabel : dateLabelAr}
                  </Typography>
                </Box>
                <Typography variant="subtitle2">
                  {isEnglish ? name : nameAr}
                </Typography>
              </CardContent>
            </Card>
          </Box>
        </ShadowBox>
      </Link>
    </Grid>
  );
};

export default EventCard;
