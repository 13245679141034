import { Box, Grid, Typography } from "@material-ui/core";

//Components
import EventCard from "./EventCard";

//Translations
import { useTranslation } from "../../../i18n";

const EventList = ({ events, color, landing }) => {
  const { t } = useTranslation("events");

  const eventCards = events.map((event) => (
    <EventCard key={event.id} {...event} grey={landing} />
  ));

  return (
    <>
      <Box color={color} mb={6}>
        <Typography variant="h3">{t("eventsListHeader")}</Typography>
      </Box>
      <Grid container spacing={5}>
        {eventCards}
      </Grid>
    </>
  );
};
export default EventList;
