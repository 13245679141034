//Components
import HomeHeader from "./HomeHeader";
import About from "./About";
import Goals from "./Goals";
import Events from "./Events";
import Gallery from "./Gallery";
import Teams from "./Teams";

const Home = ({ events, galleryImages, teams }) => {
  return (
    <>
      <HomeHeader />
      <About />
      <Goals />
      <Events events={events} />
      <Gallery galleryImages={galleryImages} />
      <Teams teams={teams} />
    </>
  );
};

export default Home;
