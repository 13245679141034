import React from "react";
import { Box, Card, CardContent, Typography } from "@material-ui/core";

// Components
import { GiantAvatar } from "../../common";

const TeamMemberCard = ({ role, name, bio, image, landing }) => {
  return (
    <Box height="100%" clone>
      <Card>
        <CardContent component={Box} bgcolor={landing ? "white" : "default"} >
          <Box display="flex" justifyContent="center" py={2}>
            <GiantAvatar alt="name" src={image} grey={landing} />
          </Box>
          <Box color={landing ? "common.white" : "default"}>
            <Typography variant="button">{name}</Typography>
          </Box>
          <Typography variant="subtitle2">{role}</Typography>
          <Typography variant="body1">{bio}</Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default TeamMemberCard;
