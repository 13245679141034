import React from "react";
import { Box, Typography, Hidden } from "@material-ui/core";

//Components
import { ChunkyCarousel } from "../../common";
import TeamMemberCard from "./TeamMemberCard";

// Translation
import { useTranslation } from "../../../i18n";

const TeamMembers = ({ color, roles, name, nameAr, landing }) => {
  const {
    i18n: { language },
  } = useTranslation();

  const isEnglish = language === "en";

  const teamCards = roles.map(
    ({ id, role, roleAr, teamMember: { name, nameAr, bio, bioAr, image } }) => (
      <TeamMemberCard
        key={id}
        role={isEnglish ? role : roleAr}
        name={isEnglish ? name : nameAr}
        bio={isEnglish ? bio : bioAr}
        image={image}
        landing={landing}
      />
    )
  );

  return (
    <Box mb={5}>
      <Box color={color} mb={3}>
        <Typography variant="h4" gutterBottom>
          {isEnglish ? name : nameAr}
        </Typography>
      </Box>
      <Hidden mdUp>
        <ChunkyCarousel spacing={5}>{teamCards}</ChunkyCarousel>
      </Hidden>
      <Hidden smDown>
        <ChunkyCarousel chunks={4} spacing={5}>
          {teamCards}
        </ChunkyCarousel>
      </Hidden>
    </Box>
  );
};

export default TeamMembers;
