import { Grid, Box, Typography } from "@material-ui/core";

//Translation
import { useTranslation } from "../../../i18n";

const GoalCard = ({ title, titleAr, description, descriptionAr, icon }) => {
  const {
    i18n: { language },
  } = useTranslation();

  const isEnglish = language === "en";

  return (
    <Grid item md={3}>
      {icon}
      <Box my={1}>
        <Typography variant="subtitle1">
          {isEnglish ? title : titleAr}
        </Typography>
      </Box>
      <Typography>{isEnglish ? description : descriptionAr}</Typography>
    </Grid>
  );
};

export default GoalCard;
