import {
  Grid,
  Typography,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";

//Translation
import { useTranslation } from "../../../i18n";

// Components
import { FullWidthImage, Section } from "../../common";

//Data
import bullets from "./bullets";

const About = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation("home");

  const isEnglish = language === "en";

  const items = bullets[language].map((text, idx) => (
    <ListItem key={text}>
      <ListItemIcon>
        <Box color="white">{bullets.icons[idx]}</Box>
      </ListItemIcon>
      <ListItemText
        primaryTypographyProps={{ align: isEnglish ? "left" : "right" }}
        primary={text}
      />
    </ListItem>
  ));

  return (
    <Section
      id="about"
      pt={5}
      pb={0}
      background="mirinda"
      bgimage={"/images/1.png"}
    >
      <Grid container alignItems="center" justify="space-between">
        <Box color="white" clone>
          <Grid item md={5}>
            <Typography variant="h1">{t("about.title")}</Typography>
            <List>
              <ListItem>
                <ListItemText
                  primaryTypographyProps={{
                    align: isEnglish ? "left" : "right",
                  }}
                  primary={t("about.tableHeader")}
                />
              </ListItem>
              {items}
            </List>
          </Grid>
        </Box>
        <Grid item md={5}>
          <Box mb={3} clone borderRadius={15} boxShadow={3}>
            <FullWidthImage src={"/images/sec2.jpg"} role="presentation" />
          </Box>
        </Grid>
      </Grid>
    </Section>
  );
};

export default About;
