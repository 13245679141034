import Head from "next/head";

// Apollo
import { initializeApollo } from "../apollo";
import { useQuery } from "@apollo/client";

//Translations
import { useTranslation } from "../i18n";

// Components
import Home from "../components/Home";

//Queries
import gql from "graphql-tag";

const HOME_QUERY = gql`
  {
    events(limit: 3) {
      id
      name
      nameAr
      dateLabel
      dateLabelAr
      slug
      images {
        image
        caption
        captionAr
      }
    }
    galleryImages(limit: 6) {
      image
    }
    teams(highlighted: true) {
      id
      name
      nameAr
      roles {
        id
        role
        roleAr
        teamMember {
          name
          nameAr
          bio
          bioAr
          image
        }
      }
    }
  }
`;

const _Home = () => {
  const { data } = useQuery(HOME_QUERY);
  const { t } = useTranslation("home");

  return (
    <>
      <Head>
        <title>{t("pageTitle")}</title>
      </Head>
      <Home {...data} />
    </>
  );
};

export default _Home;

export async function getStaticProps() {
  const client = initializeApollo();

  await client.query({
    query: HOME_QUERY,
  });

  return {
    props: {
      initialApolloState: client.cache.extract(),
    },
    revalidate: 100,
  };
}
