import { Grid, Box, Typography } from "@material-ui/core";

//Translation
import { useTranslation } from "../../../i18n";

// Data
import goals from "./goals";

// Components
import { Section } from "../../common";
import GoalCard from "./GoalCard";

const Goals = () => {
  const { t } = useTranslation("home");

  const goalCards = goals.map((goal) => (
    <GoalCard key={goal.title} {...goal} />
  ));
  return (
    <Section py={20} bgcolor="common.white">
      <Box mb={6}>
        <Typography variant="h3">{t("goals.title")}</Typography>
      </Box>
      <Grid container spacing={5}>
        {goalCards}
      </Grid>
    </Section>
  );
};

export default Goals;
